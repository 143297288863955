<template>
    <h4>{{ $t('general.facturacion') }}</h4>
    <div class="card">

        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Bancos')" href="#" class="info-box">
                        <span class="info-box-icon bg-success"><i class="fas fa-university"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.bancos') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4" v-if="baremosact && (permisosusuario.includes('Administración') || permisosusuario.includes('Super Administración'))">
                    <a @click.prevent="abrirConfiguracion('Configuración baremos')" href="#" class="info-box">
                        <span class="info-box-icon bg-success"><i class="fas fa-list"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.baremos') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4" v-if="permisosusuario.includes('Administración') || permisosusuario.includes('Super Administración')">
                    <a @click.prevent="abrirConfiguracion('Condiciones Presupuestos')" href="#" class="info-box">
                        <span class="info-box-icon bg-success"><i class="fas fa-list-ol"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.Condiciones Presupuestos') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4" v-if="permisosusuario.includes('Administración') || permisosusuario.includes('Super Administración')">
                    <a @click.prevent="abrirConfiguracion('Exportación contable')" href="#" class="info-box">
                        <span class="info-box-icon bg-success"><i class="fas fa-cogs"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.exportacion') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4" v-if="permisosusuario.includes('Administración') || permisosusuario.includes('Super Administración')">
                    <a @click.prevent="abrirConfiguracion('Líneas Facturación')" href="#" class="info-box">
                        <span class="info-box-icon bg-success"><i class="fas fa-file-export"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.lineasfacturacion') }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
export default({
    props:['permisosusuario'],
    mounted() {
        this.comprobarBaremos();
    },
    data(){
        return{
            baremosact: false,
        }
    },
    methods: {
        abrirConfiguracion(configuracion){
            this.$router.push({
                name: configuracion
            });
        },
        async comprobarBaremos(){
            const api = new PwgsApi();
            const resp = await api.get('parametros-pwgs/gestion_baremos');
            console.log('respi',resp.gestion_baremos);
            if(resp.gestion_baremos==1){
                console.log('entroo');
                this.baremosact = true;
            }
        },
    }
})
</script>